import React, { useState, useEffect } from "react";
import { Carousel, Button, Container, Row, Col } from "react-bootstrap";
import "./Story.css";

// Full storyMap
const storyMap = {
  question1: {
    questionContent:
      "When you arrive at Coral Cove, you see a lot of plastic and garbage floating in the water. The fish can’t swim easily, and the coral looks sick. You have to do something!",
    image: "/stories/1.png",
    "Organize a Beach Cleanup": {
      result:
        "The beach is now clean! But more trash keeps washing in from the ocean because the garbage from far away is still being carried into the water. Cleaning the beach helps, but it’s not a complete solution.",
      next: "question2",
      score: 0,
    },
    "Ban Plastic in the Town": {
      result:
        "The town stops using plastic, and the water and streets are much cleaner. This is great for the environment! But some people aren’t happy because it’s harder to give up plastic items like bags and straws, which they’re used to.",
      next: "question2",
      score: 2,
    },
    "Ignore the Trash": {
      result:
        "The trash keeps piling up. The beach and water get dirtier, and the fish and other sea animals have trouble finding clean water to live in. Ignoring the problem only makes things worse.",
      next: "question2",
      score: -2,
    },
  },
  question2: {
    questionContent:
      "You’ve cleaned up some of the trash, but more keeps coming in! Now you need a longer-term solution. What will you do next?",
    image: "/stories/2.png",
    "Put Trash Nets in the River": {
      result:
        "Good job! The nets catch the trash that flows in from the rivers before it reaches the ocean. But they fill up quickly and need to be cleaned regularly, or else the trash will still get into the sea.",
      next: "question3",
      score: 1,
    },
    "Start a No-Plastic Campaign": {
      result:
        "People in town are using less plastic, which means less trash ends up in the ocean. But it will take time for the changes to make a big difference, so we have to be patient.",
      next: "question3",
      score: 2,
    },
    "It’s Too Much Work": {
      result:
        "The trash keeps flowing into the water, making the beach and ocean dirtier. The problem gets bigger, and it becomes harder to fix because nothing is being done.",
      next: "question3",
      score: -2,
    },
  },
  question3: {
    questionContent:
      "Now the coral is turning white! This happens when the water gets too warm because of climate change. The coral needs your help! What will you do?",
    image: "/stories/3.png",
    "Plant New Coral": {
      result:
        "New coral is growing, and little fish have a safe place to live again! But because the ocean is still too warm, too much algae starts growing, which can block sunlight from reaching the coral.",
      next: "question4",
      score: 1,
    },
    "Build Shade for the Coral": {
      result:
        "The coral is cool for now under the shade, and it helps them survive in the warmer water. But this is only a temporary solution, as it doesn’t fix the cause of the warmer water.",
      next: "question4",
      score: 0,
    },
    "Do Nothing": {
      result:
        "The coral keeps dying because of the warm water, and the sea creatures that live in the reef are losing their homes. Not taking action means the problem will continue to get worse.",
      next: "question4",
      score: -2,
    },
  },
  question4: {
    questionContent:
      "The coral is recovering, but now there’s too much algae! What will you do to help the coral grow without being smothered?",
    image: "/stories/4.png",
    "Bring in Fish to Eat the Algae": {
      result:
        "The fish eat the algae and help the coral, but now there are too many fish competing for food. This could lead to more problems if the fish don’t have enough to eat.",
      next: "question5",
      score: 0,
    },
    "Organize Algae Clean-Up Days": {
      result:
        "With your help, the algae is cleared away, and the coral has space to grow! But it takes a lot of effort from everyone, and we have to keep working together to keep the coral healthy.",
      next: "question5",
      score: 2,
    },
    "Let the Algae Grow": {
      result:
        "Oh no! The algae keeps growing, blocking the sunlight, and the coral starts to die. Without help, the coral can’t survive in these conditions.",
      next: "question5",
      score: -2,
    },
  },
  question5: {
    questionContent:
      "Now that the coral is recovering, you notice there’s another problem: people are catching too many fish! If they keep fishing like this, there won’t be enough fish left in Coral Cove. What will you do?",
    image: "/stories/5.png",
    "Make Coral Cove a No-Fishing Zone": {
      result:
        "The fish are safe and have time to grow, but some fishermen are upset because they can’t catch fish in this area anymore. It’s important to protect the fish, but we also need to consider the fishermen’s needs.",
      next: "question6",
      score: 2,
    },
    "Teach Fishermen to Fish Carefully": {
      result:
        "The fishermen are now catching fewer fish, which helps protect the fish population. But it’s going to take a long time for the fish to grow back to healthy levels.",
      next: "question6",
      score: 1,
    },
    "Let the Fishermen Keep Fishing": {
      result:
        "Oh no! The fish population keeps shrinking, and soon there won’t be enough left. Letting people overfish means there won’t be enough fish for the future.",
      next: "question6",
      score: -2,
    },
  },
  question6: {
    questionContent:
      "The fish population is starting to recover, but it will take time. How will you help make sure it keeps growing?",
    image: "/stories/6.png",
    "Monitor the Fish Population": {
      result:
        "The fish are slowly coming back, but it’s taking a lot of time. By watching the fish population closely, we can make sure we’re not fishing too many at once.",
      ending: "score-dependent",
      score: 1,
    },
    "Sell Safe Fish in Stores": {
      result:
        "People are buying safe, responsibly caught fish, which helps both the fishermen and the ocean! This is a great way to support healthy fishing practices.",
      ending: "score-dependent",
      score: 2,
    },
    "Let People Catch All The Fish They Want": {
      result:
        "Oh no! The fish population drops again because too many were caught, and soon there aren’t enough fish left to keep the ecosystem healthy.",
      ending: "score-dependent",
      score: -2,
    },
  },
};

const StoryCarousel = () => {
  const [currentQuestion, setCurrentQuestion] = useState("question1");
  const [selectedChoice, setSelectedChoice] = useState(null);
  const [score, setScore] = useState(0);
  const [endMessage, setEndMessage] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0); //carousel control
  const [showFinalCard, setShowFinalCard] = useState(false); //final result card
  const [endImage, setEndImage] = useState("");


  const handleChoice = (choice) => {
    setSelectedChoice(choice);
    setShowResult(true);
  };

  useEffect(() => {
    if (showResult && selectedChoice) {
      const choiceData = storyMap[currentQuestion]?.[selectedChoice];
      if (choiceData) {
        // Safely update the score using previous value
        setScore((prevScore) => {
          const updatedScore = prevScore + choiceData.score;
  
          // If there's no next question, set the final result message and image
          if (!choiceData.next) {
            const finalResult = getEndMessage(updatedScore);
            setEndMessage(finalResult.message);
            setEndImage(finalResult.image);
          }
  
          return updatedScore;  // Return the new score
        });
      }
    }
  }, [showResult, selectedChoice, currentQuestion]);  // Removed `score` from dependencies
  
  

  const getEndMessage = (finalScore) => {
    if (finalScore >= 6) {
      return {
        message: "Good job! You saved Coral Cove! The coral is healthy, and the fish are back!",
        image: "/result/1.png"
      };
    } else if (finalScore >= 2) {
      return {
        message: "Not bad! Coral Cove is getting better, but it still needs more work.",
        image: "/result/2.png"
      };
    } else {
      return {
        message: "Oh no! Coral Cove is in trouble. The fish and coral are struggling.",
        image: "/result/3.png"
      };
    }
  };

  const handleNext = () => {
    // If the endMessage exists, go to the final result card
    if (endMessage) {
      setShowFinalCard(true);
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      // Move to the next question in the carousel
      const nextQuestionKey = storyMap[currentQuestion]?.[selectedChoice]?.next;
      if (nextQuestionKey) {
        setCurrentQuestion(nextQuestionKey);
        setCurrentIndex((prevIndex) => prevIndex + 1);
        setShowResult(false); // Reset result state for the next question
        setSelectedChoice(null); // Reset the selected choice
      }
    }
  };

  const restartStory = () => {
    // Reset all necessary states to start the story fresh
    setCurrentQuestion("question1");
    setScore(0);
    setEndMessage("");
    setSelectedChoice(null);
    setShowResult(false);
    setCurrentIndex(0);
    setShowFinalCard(false);
    setEndImage("");
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col md={12}>
          <Carousel
            activeIndex={currentIndex}
            onSelect={() => {}} // Disable automatic carousel movement
            indicators={false}
            controls={false}
            interval={null}
          >
            {/* Introductory carousel item for the scenario */}
            <Carousel.Item>
              <div
                className="card quiz-card"
                style={{
                  minHeight: "400px",
                  display: "flex",
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundImage: `url('/stories/7.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="card-header text-center">
                  <h1 className="question-heading">
                    Coral Cove Needs Your Help!
                  </h1>
                </div>
                <div className="card-body text-center">
                  <p className="story-text">
                    Coral Cove is a small costal town with beautiful ocean
                    around. But lately, the ocean has been filling with plastic
                    and garbage, the coral is turning white, and the fish are
                    disappearing.
                  </p>
                  <p className="story-text">
                    As a Marine Guardian, you are going to make smart choices
                    and save Coral Cove. Can you help bring it back to life?
                  </p>
                  <p className="story-text">
                    Let’s get started — Coral Cove is counting on you!
                  </p>
                  <Button
                    className="start-button"
                    onClick={() => setCurrentIndex(1)} // Move to the first question
                  >
                    Start Saving Coral Cove
                  </Button>
                </div>
              </div>
            </Carousel.Item>

            {/* Render each question card */}
            {Object.keys(storyMap).map((key, index) => (
              <Carousel.Item
                key={key}
                style={{
                  backgroundImage: `url(${storyMap[key].image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  minHeight: "400px",
                }}
              >
                <div className="card quiz-card">
                  <div className="card-header text-center">
                    <h5 className="question-text">
                      {storyMap[key].questionContent}
                    </h5>
                  </div>
                  <div className="card-body d-flex">
                    <div className="choices-container">
                      <h5 className="question-text">What would you do?</h5>
                      <div className="d-grid gap-2 col-2 mx-auto my-3 choice-buttons">
                        {Object.keys(storyMap[key])
                          .filter(
                            (choiceKey) =>
                              choiceKey !== "questionContent" &&
                              choiceKey !== "image"
                          )
                          .map((choice) => (
                            <Button
                              key={choice}
                              onClick={() => handleChoice(choice)}
                              disabled={showResult && selectedChoice !== choice}
                            >
                              {choice}
                            </Button>
                          ))}
                      </div>
                    </div>
                    <div className="result-container">
                      {showResult &&
                        selectedChoice &&
                        storyMap[currentQuestion]?.[selectedChoice] && (
                          <div className="result-text">
                            <p>
                              {storyMap[currentQuestion][selectedChoice].result}
                            </p>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="card-footer d-flex justify-content-end">
                    {showResult && (
                      <Button
                        className="btn btn-outline-light btn btn-primary"
                        onClick={handleNext}
                      >
                        {endMessage ? (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fillRule="currentColor"
                              className="bi bi-check-circle-fill me-2"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                            Finish
                          </>
                        ) : (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fillRule="currentColor"
                              className="bi bi-arrow-right-circle-fill me-2"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                            </svg>
                            Next
                          </>
                        )}
                      </Button>
                    )}
                  </div>
                </div>
              </Carousel.Item>
            ))}

            {/* Render final result card */}
            {showFinalCard && (
              <Carousel.Item>
                <div className="card quiz-card">
                  <div className="card-header text-center">
                    <h1 className="question-heading">
                      Did you save Coral Cove?{" "}
                    </h1>
                  </div>
                  <div className="card-body text-center">
                  {endImage && <img src={endImage} alt="Final Result" className="final-result-image" />}
                  <p className="story-text">{endMessage}</p>
                    <Button className="start-button" onClick={restartStory}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fillRule="currentColor"
                        className="bi bi-arrow-clockwise"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                        />
                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                      </svg>
                      Restart Story
                    </Button>
                  </div>
                </div>
              </Carousel.Item>
            )}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
};

export default StoryCarousel;