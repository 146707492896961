import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import Header from "./Header";
import "./OceanConservation.css";
import Book from "./FlipBook";
import QuizPopup from "./QuizPopup";
import StoryCarousel from "./Story";

const conservationTechniques = [
  {
    id: 1,
    title: "Fishing Bans (No Fishing Zones)",
    imageUrl: "/technique/fishing-bans-bg.png", // Replace with your actual image path
    details: {
      whatItMeans:
        "In some parts of the ocean, people are not allowed to fish. This gives fish, and other sea creatures, time to grow and have babies.",
      whyImportant:
        "If people catch too many fish, there won’t be enough left for the future. So, these areas help keep the ocean full of life!",
      australiaInvolvement:
        'Australia has many "no-take" zones, especially around the Great Barrier Reef, where fishing is banned to protect fish and coral. These protected areas help marine life recover and thrive.',
    },
  },
  {
    id: 2,
    title: "Cleaning up Ocean Trash",
    imageUrl: "/technique/cleaning-ocean-trash-bg.png", // Replace with your actual image path
    details: {
      whatItMeans:
        "People work together to clean up trash like plastic bags and bottles from the beach and ocean.",
      whyImportant:
        "Trash can hurt animals like turtles and fish. Cleaning it up keeps the ocean safe for all the creatures that live there.",
      australiaInvolvement:
        'Australia is part of the global movement to clean up ocean trash. Big events like "Clean Up Australia Day" encourage people to pick up litter from beaches, rivers, and parks to keep the environment healthy for animals and people.',
    },
  },
  {
    id: 3,
    title: "Protecting and Restoring Coral Reefs",
    imageUrl: "/technique/protecting-coral-reefs-bg.png", // Replace with your actual image path
    details: {
      whatItMeans:
        "Coral reefs are like underwater cities where fish and sea animals live. Sometimes corals get damaged, so people help by planting new coral and taking care of the reefs.",
      whyImportant:
        "Healthy coral reefs help protect fish, turtles, and many other sea creatures by giving them a safe home. Restoring damaged reefs helps bring back life to these areas.",
      australiaInvolvement:
        "The Great Barrier Reef, one of the largest reefs in the world, is located in Australia. Scientists are working hard to protect and restore it by planting new coral and making sure the reefs are healthy.",
    },
  },
  {
    id: 4,
    title: "Marine Protected Areas (MPAs)",
    imageUrl: "/technique/marine-protected-areas-bg.png", // Replace with your actual image path
    details: {
      whatItMeans:
        "Some parts of the ocean are protected by the government, and no one is allowed to harm the animals or plants there.",
      whyImportant:
        "MPAs give sea animals a safe place to live without worrying about boats, fishing, or pollution.",
      australiaInvolvement:
        "Australia has some of the largest Marine Protected Areas in the world, covering millions of square kilometers of ocean. These protected zones help keep the Great Barrier Reef and other marine environments safe from damage.",
    },
  },
  {
    id: 5,
    title: "Reducing Ocean Pollution (Chemical and Oil Spills)",
    imageUrl: "/technique/reducing-ocean-pollution-bg.png", // Replace with your actual image path
    details: {
      whatItMeans:
        "Sometimes harmful chemicals or oil spill into the ocean and can hurt sea animals. People work to stop these spills and clean them up.",
      whyImportant:
        "Reducing ocean pollution helps keep the water safe and clean for all marine life, like fish, turtles, and whales.",
      australiaInvolvement:
        "Australia has strict rules about chemicals and oil spills. If a spill happens, the government works fast to clean it up and protect the animals in the water.",
    },
  },
  {
    id: 6,
    title: "Marine Animal Rehabilitation",
    imageUrl: "/technique/marine-animal-rehabilitation-bg.png", // Replace with your actual image path
    details: {
      whatItMeans:
        "Sometimes sea animals get hurt by boats, fishing nets, or pollution. There are special rescue centers where people help these animals get better so they can go back to the ocean.",
      whyImportant:
        "Rescue centers help sea animals like turtles, dolphins, and seals heal from their injuries and live longer, healthier lives.",
      australiaInvolvement:
        "Australia has many marine animal rescue centers. They care for injured sea animals until they’re strong enough to return to the ocean. Some places even let kids visit to learn how they help animals!",
    },
  },
  {
    id: 7,
    title: "International Agreements (e.g., CITES, UNCLOS)",
    imageUrl: "/technique/international-agreements-bg.png", // Replace with your actual image path
    details: {
      whatItMeans:
        "Countries around the world agree to follow certain rules to protect the ocean and its animals. They work together to make sure the ocean stays healthy.",
      whyImportant:
        "When all countries agree to protect the ocean, it helps make sure sea animals are safe no matter where they swim.",
      australiaInvolvement:
        "Australia is part of global agreements like CITES, which protects endangered animals like sea turtles, and UNCLOS, which helps keep the ocean clean and safe by making sure countries take care of their parts of the ocean.",
    },
  },
];

const conservationAction = {
  trash: {
    title: "Throw Trash in the Trash Bins",
    imageUrl: "/hacks/trash_bin.png",
    details: `Did you know that when you throw trash in sewers or streets,  rain can carry it into rivers and oceans? According to a study, around 1.7 million tonnes of plastic end up in the ocean – that's as big as an island! Plastics don't go away quickly, and it can harm fish. As they eat plastic thinking it's food and it could even make them sick or choke.`,
  },
  singleUse: {
    title: "Reduce Single-Use Plastics",
    imageUrl: "/hacks/single_use.png",
    details: `Single-use plastics like water bottles, plates, and spoons are used only once and then thrown away. These plastics have been polluting our oceans for a long time. Instead of them, try using metal straws, bring a lunchbox for takeout, or refill a water bottle instead of buying new ones to keep the ocean clean and safe for animals.`,
  },
  saveWater: {
    title: "Save Water",
    imageUrl: "/hacks/save_water.png",
    details: `Use less water when you shower or wash things. The water we use flows into sewers, which may carry harmful chemicals to the ocean. This can make fish sick and cause algae to grow and too much of algae occupies the surface of the ocean and makes it hard for the oxygen to reach the fish. So, save water to help your ocean friends!`,
  },
  share: {
    title: "Share the Words",
    imageUrl: "/hacks/share_word.png",
    details: `You don't have to do this alone. Ask your friends and family to help in protecting our oceans and animals. Tell them how pollution harms marine animals. You'll earn cool points with your friends and spend quality time with your family.`,
  },
};

const quizData = {
  topic: 'Ocean Helper',
  level: 'General Knowledge',
  totalQuestions: 4,
  perQuestionScore: 10,
  questions: [
    {
      question: 'According to Ms. Southern Short-finned Eel in the video, where are their homes?',
      choices: ['Forests and Mountains', 'Rivers and Oceans', 'Deserts and Lakes', 'Gardens and Ponds'],
      correctAnswer: 'Rivers and Oceans',
      explanation: 'Ms. Southern Shortfinned Eel highlights that their homes are in rivers and oceans, emphasizing the adaptability of eels to various aquatic environments. This diverse habitat allows them to thrive in both fresh and saltwater.',
      image: "/animalquiz/a.png"
    },
    {
      question: 'According to Ms. Southern Short-finned Eel in the video, why do baby Southern Short-finned Eels go to the river?',
      choices: ['To grow up', 'To play games', 'To find food', 'To sleep'],
      correctAnswer: 'To grow up',
      explanation: 'Baby Southern Shortfinned Eels travel to rivers to grow and develop. The river provides a safer environment with abundant food and shelter, which supports their growth until they return to the ocean.',
      image: "/animalquiz/b.png"
    },
    {
      question: 'What is one way to take care of oceans and marine species?',
      choices: ['Building sandcastles', 'Swimming more', 'Planting trees', 'Cleaning up the trash'],
      correctAnswer: 'Cleaning up the trash',
      explanation: 'One effective way to care for our oceans and marine species is by cleaning up trash. This prevents pollution that can harm marine life and disrupt their habitats. Learn more about ocean cleanup efforts [here](https://oceanconservancy.org/trash-free-seas/).'
    },
    {
      question: 'According to you, what is one way to help with home conservation?',
      choices: ['Watching more TV', 'Buying more toys', 'Reducing single-use plastic', 'Eating more candy'],
      correctAnswer: 'Reducing single-use plastic',
      explanation: 'Reducing the use of single-use plastic at home is a significant step toward conservation. It decreases plastic waste, which often ends up in our oceans, harming marine life. Discover simple tips for reducing plastic use [here](https://www.earthday.org/actions/end-plastic-pollution/).'
    }
  ],
};


const OceanConservation = () => {
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef(null);
  const [activeTechnique, setActiveTechnique] = useState(null);
  const [animationClass, setAnimationClass] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupContent, setPopupContent] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [audioSrc, setAudioSrc] = useState(""); // Track the current audio source
  const audioRef = useRef(null);
  const [isQuizOpen, setIsQuizOpen] = useState(false);

  const handleButtonClick = (detailKey, technique) => {
    const titleMap = {
      "What it is?": "whatItMeans",
      "Why it’s important?": "whyImportant",
      "What are we doing?": "australiaInvolvement",
    };

    setPopupTitle(detailKey);
    setPopupContent(technique.details[titleMap[detailKey]]);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleTechniqueClick = (type) => {
    setActiveTechnique(conservationAction[type]);
  };

  useEffect(() => {
    if (activeTechnique) {
      setAnimationClass("pollution-detail-active");
    } else {
      setAnimationClass("pollution-detail-inactive");
      const timer = setTimeout(() => setAnimationClass(""), 600);
      return () => clearTimeout(timer);
    }
  }, [activeTechnique]);

  const goToRoute = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const handleVideoControl = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleFlip = (e) => {
    setCurrentPage(e.data); // Set the current page based on the flip event data
    console.log("current page", currentPage);
  };

  useEffect(() => {
    // Pause the current audio when the page changes
    if (audioRef.current) {
      audioRef.current.pause();
    }

    // Set audio source based on the current page range
    if (currentPage === 2 || currentPage === 3) {
      setAudioSrc("/story/1.m4a");
    } else if (currentPage === 4 || currentPage === 5) {
      setAudioSrc("/story/2.m4a");
    } else if (currentPage === 6 || currentPage === 7) {
      setAudioSrc("/story/3.m4a");
    } else {
      setAudioSrc("");
    }

    console.log("audio source", audioSrc);
  }, [currentPage, audioSrc]); // Re-run when the current page or audio source changes

  const Popup = ({ title, message, closePopup }) => {
    return (
      <div className="popup">
        <div className="popup-content">
          {title && <h2 className="popup-title">{title}</h2>}
          <p>{message}</p>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={closePopup}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-x-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"></path>
            </svg>
          </button>
        </div>
      </div>
    );
  };

  return (
    <main>
      <Header />
      <div id="marine-container">
        {/* Intro video */}
        <div id="video-container">
          <video id="intro-video" ref={videoRef} autoPlay>
            <source
              src="/videos/Southern Short-Finned Eel.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <button id="video-control" onClick={handleVideoControl}>
            {isPlaying ? <span>&#10074;&#10074;</span> : <span>&#9658;</span>}
          </button>
        </div>

        {/* Conservation techniques carousel */}
        <div id="section-container">
          <div className="text-container">
            <h3 className="section-heading">
              How The World Protects The Ocean
            </h3>
            <p className="section-text">
              From keeping the water clean to saving sea animals, there are lots
              of things the world is doing to make a difference.
            </p>
            <p className="section-text">
              Explore how countries like Australia and others are working
              together to keep our oceans safe and full of life!
            </p>
          </div>
          <div className="carousel-container">
            <Carousel interval={null}>
              {conservationTechniques.map((technique, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={technique.imageUrl}
                    alt={technique.title}
                  />
                  <Carousel.Caption>
                    <div className="carousel-buttons">
                      <button
                        onClick={() =>
                          handleButtonClick("What it is?", technique)
                        }
                      >
                        What it is?
                      </button>
                      <button
                        onClick={() =>
                          handleButtonClick("Why it’s important?", technique)
                        }
                      >
                        Why it’s important?
                      </button>
                      <button
                        onClick={() =>
                          handleButtonClick("What are we doing?", technique)
                        }
                      >
                        What are we doing?
                      </button>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>

            {showPopup && (
              <Popup
                title={popupTitle}
                message={popupContent}
                closePopup={closePopup}
              />
            )}
          </div>
        </div>

        {/* At-home conservation */}
        <div id="section-container">
          <div className="container mt-2">
            {activeTechnique ? (
              <div className={`pollution-detail ${animationClass}`}>
                <h3>{activeTechnique.title}</h3>
                <img
                  src={activeTechnique.imageUrl}
                  alt={activeTechnique.title}
                  className="conservation-icon img-fluid mb-4"
                />
                <p>{activeTechnique.details}</p>
                <button
                  onClick={() => setActiveTechnique(null)}
                  type="button"
                  className="btn btn-outline-light"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    fill="currentColor"
                    className="bi bi-arrow-left-circle-fill"
                    viewBox="0 0 18 16"
                  >
                    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"></path>
                  </svg>
                  Back
                </button>
              </div>
            ) : (
              <div className="row">
                <h3 className="section-heading">
                  At-Home Conservation Actions
                </h3>
                <p className="section-text">
                  Even if you don't live near the ocean, there is still so much
                  you can do to help!
                </p>
                {Object.entries(conservationAction).map(([type, data]) => (
                  <div
                    key={type}
                    className="col-6 col-sm-4 col-md-3 text-center mb-4"
                  >
                    <img
                      src={data.imageUrl}
                      alt={data.title}
                      className="pollution-icon"
                      style={{ cursor: "pointer", marginBottom: "10px" }}
                      onClick={() => handleTechniqueClick(type)}
                    />
                    <button
                      type="button"
                      className="btn btn-outline-light subsection"
                      onClick={() => handleTechniqueClick(type)}
                    >
                      {data.title}
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div id="section-container-book">
          <div className="flipbook-wrapper">
            <div className="audio">
              {audioSrc && (
                <audio ref={audioRef} src={audioSrc} controls></audio>
              )}
              </div>
            <Book onFlip={handleFlip} />
          </div>
        </div>
        </div>

                {/* quiz   */}
                <div id="section-container">
        <div id="intro-container">
          <img src="quiz.png" alt="quiz" style={{width:"500px"}} />
          <p className="section-text">
            Ready to take a quiz and test what you've learnt?
          </p>
          <button
            type="button"
            className="btn btn-outline-light"
            onClick={() => setIsQuizOpen(true)}
          >
            Start Quiz
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              fill="currentColor"
              className="bi bi-arrow-right-circle-fill ms-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
            </svg>
          </button>
          <QuizPopup
            isOpen={isQuizOpen}
            onClose={() => setIsQuizOpen(false)}
            quizData={quizData}
          />
        </div>
        </div>

        <div id="section-container">
        <div className="row">
            <h3 className="section-heading">
              Save Coral Cove
            </h3>
            <p className="section-text">
            In here, you are going to be a marine guardian and see how your decisions impact the future of our seas!
            </p>
            <p className="section-text">
              Play to learn how small actions can make a big difference in saving marine life and ocean environment.
            </p>
          <StoryCarousel/>
          </div>
          </div>    

        {/* section summary  */}
        <div id="intro-container">
          <h4 className="section-heading">
            Great job, young marine guardian! 🎉
          </h4>
          <p className="section-text">
            You've learned so much about our beautiful oceans in this journey.
          </p>
          <p className="section-text">
          Remember, every small action you take can help protect our oceans.
          </p>
          <p className="section-text">
          Keep exploring, stay curious, and use what you've learned to be a guardian to our ocean!
          </p>
          <p className="section-text">
            Sit tight, more content is on the way!
          </p>
          {/* <button
            type="button"
            className="btn btn-outline-light"
            onClick={() => goToRoute("/")}
          >
            Next
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              fill="currentColor"
              className="bi bi-arrow-right-circle-fill ms-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
            </svg>
          </button> */}
          <button
            type="button"
            className="btn btn-outline-light"
            onClick={() => goToRoute("/")}
          >
            Back to Home
          </button>
        </div>
    </main>
  );
};

export default OceanConservation;
