import React from "react";

const Footer = () => {
  return (
    <footer className="text-muted py-1" data-bs-theme="dark">
      <div className="container d-flex justify-content-center align-items-center">
        <div className="d-inline-flex align-items-center">
          <p className="mb-0">@Splash by Seaweed 2024</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
