import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import EndangeredSpecies from "./components/EndangeredSpecies";
import Footer from "./components/Footer";
import Home from "./components/Home";
import MarineThreats from "./components/MarineThreats";
import OceanPollution from "./components/OceanPollution";
import OceanConservation from "./components/OceanConservation";
import Password from "./components/password";

function App() {
  return (
    <Router>
      <Password correctPassword={"awesometp30"}>
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/endangered-species" element={<EndangeredSpecies />} />
            <Route path="/ocean-pollution" element={<OceanPollution />} />
            <Route path="/marine-threats" element={<MarineThreats />} />
            <Route path="/ocean-conservation" element={<OceanConservation />} />
          </Routes>
        </div>
        <Footer />
      </Password>
    </Router>
  );
}

export default App;
