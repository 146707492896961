import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import "./MarineThreats.css";
import QuizPopup from "./QuizPopup";


const quizData = {
  topic: 'Ocean Threats',
  level: 'General Knowledge',
  totalQuestions: 5,
  perQuestionScore: 10,
  questions: [
    {
      question: 'How many years has Mr. Humpback Whale been swimming in the ocean?',
      choices: ['70 years', '30 years', '5 years', '80 years'],
      correctAnswer: '80 years',
      explanation: 'Mr. Humpback Whale has been navigating the ocean waters for 80 years, experiencing many changes during this time.'
    },
    {
      question: 'Mr. Humpback Whale says which of his friends are starting to disappear?',
      choices: ['Tuna and Swordfish', 'Dolphins and Sharks', 'Jellyfish and Starfish', 'Seals and Penguins'],
      correctAnswer: 'Tuna and Swordfish',
      explanation: 'Tuna and Swordfish populations are diminishing, partly due to overfishing and changes in their habitats.'
    },
    {
      question: 'Mr. Humpback Whale says strange things are starting to fill the oceans. What are they?',
      choices: ['Plastic Bottles, Bags, and Oils', 'Fish, Seaweed, and Shells', 'Boats, Crabs, and Rocks', 'Sand, Bubbles, and Coral'],
      correctAnswer: 'Plastic Bottles, Bags, and Oils',
      explanation: 'The oceans are increasingly being filled with plastic bottles, bags, and oils, all of which are pollutants affecting marine life.'
    },
    {
      question: 'What kind of water is making life hard for Mr. Humpback Whale and his friends in their home?',
      choices: ['Cold Water', 'Warm Water', 'Clean Water', 'Rain Water'],
      correctAnswer: 'Warm Water',
      explanation: 'Increasing water temperatures, or warm water, is making life challenging for many marine species, including Mr. Humpback Whale.'
    },
    {
      question: 'Mr. Humpback Whale says strange things are starting to fill the oceans. What are they?',
      choices: ['Plastic Bottles, Bags, and Oils', 'Fish, Seaweed, and Shells', 'Boats, Crabs, and Rocks', 'Sand, Bubbles, and Coral'],
      correctAnswer: 'Plastic Bottles, Bags, and Oils',
      explanation: 'Pollution in the form of plastic bottles, bags, and oils is increasingly contaminating the marine environment.'
    }
  ],
};


const MarineThreats = () => {
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef(null);
  const [oceanHealthData, setOceanHealthData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [isQuizOpen, setIsQuizOpen] = useState(false);


  // nav
  const goToRoute = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  // video play/pause control
  const handleVideoControl = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // fetching marine health data when page is loaded
  const fetchData = async () => {
    try {
      console.log("Fetching marine threats data...");
      const response = await fetch("https://splash-db.onrender.com/api/health");
      // console.log("Response status:", response.status); // Log the status code
      // console.log(response);
      if (!response.ok)
        throw new Error(
          `Failed to fetch marine threats data: ${response.statusText}`
        );
      const data = await response.json();
      // console.log("Fetched data:", data);
      // sort data by date
      const sortedData = data.sort((a, b) => {
        if (a.year === b.year) {
          return a.month - b.month;
        }
        return a.year - b.year;
      });
      // set use state
      setOceanHealthData(sortedData);
    } catch (error) {
      console.error("Error details:", error);
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleIndexChange = (e) => {
    setCurrentIndex(parseInt(e.target.value));
  };

  // display filled/empty starts for ocean health score
  const getStars = (score) => {
    return (
      <>
        <span className={score >= 1 ? "star filled" : "star empty"}>★</span>
        <span className={score >= 2 ? "star filled" : "star empty"}>★</span>
        <span className={score >= 3 ? "star filled" : "star empty"}>★</span>
      </>
    );
  };

  const currentData = oceanHealthData[currentIndex] || {};

  // ocean health timeline labels
  const getTimelineLabels = () => {
    const totalLabels = 15;
    const labelInterval = Math.floor(oceanHealthData.length / totalLabels);
    return oceanHealthData.filter((_, index) => index % labelInterval === 0);
  };

  // month numeric to text
  const getMonthName = (month) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[month - 1];
  };

  // data to display when click on info
  const handleInfoClick = (type) => {
    let title = "";
    let content = "";
    switch (type) {
      case "oxygen":
        title = "Oxygen";
        content = (
          <>
            <p>
              <strong>What is it?</strong> Oxygen is a special kind of air that
              we need to breathe to stay alive. Fish and other sea animals need
              oxygen in the water to breathe, just like we need it in the air.
            </p>
            <p>
              <strong>Where does it exist?</strong> Oxygen is in the water,
              especially at the top where waves and plants like seaweed help put
              oxygen in the water.
            </p>
            <p>
              <strong>How does it affect ocean health?</strong> If there isn’t
              enough oxygen in the ocean, fish and sea animals can have trouble
              breathing, get sick, or even die. Healthy oceans have lots of
              oxygen for all the sea creatures.
            </p>
          </>
        );
        break;
      case "salinity":
        title = "Salinity";
        content = (
          <>
            <p>
              <strong>What is it?</strong> Salinity is a fancy word for how
              salty the water is. The ocean is salty because rain washes salt
              from land into the sea over a long time.
            </p>
            <p>
              <strong>Where does it exist?</strong> Salinity is in all ocean
              water, but some places are saltier than others. For example, near
              the equator, where it's very hot, more water evaporates, leaving
              extra salt behind.
            </p>
            <p>
              <strong>How does it affect ocean health?</strong> The right amount
              of salt is important for sea animals to stay healthy. If the water
              is too salty or not salty enough, it can be hard for them to live.
            </p>
          </>
        );
        break;
      case "chlorophyll":
        title = "Chlorophyll";
        content = (
          <>
            <p>
              <strong>What is it?</strong> Chlorophyll is a green color found in
              plants, including tiny plants in the ocean called phytoplankton.
              It helps these plants make food from sunlight through a process
              called photosynthesis.
            </p>
            <p>
              <strong>Where does it exist?</strong> Chlorophyll is in all green
              plants, both on land and in the ocean. In the ocean, you can find
              it in the tiny phytoplankton that float near the surface.
            </p>
            <p>
              <strong>How does it affect ocean health?</strong> Chlorophyll is
              important because it helps plants grow, and these plants are food
              for many sea animals. But if there is too much chlorophyll, it can use up too much oxygen in
              the water and make it hard for other sea creatures to live.
            </p>
          </>
        );
        break;
      default:
        title = "";
        content = "";
    }
    setPopupContent({ title, content });
    setShowPopup(true);
  };

  // close pop up window
  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <main>
      <Header />
      <div id="marine-container">
        {/* intro video  */}
        <div id="video-container">
          <video id="intro-video" ref={videoRef} autoPlay>
            <source src="/videos/Humpback Whale.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button id="video-control" onClick={handleVideoControl}>
            {isPlaying ? (
              <span>&#10074;&#10074;</span> // Pause Icon
            ) : (
              <span>&#9658;</span> // Play Icon
            )}
          </button>
        </div>

        <div id="intro-container">
          <h4 className="section-heading">
            Mr. Humpback Whale and his friends need your help!
          </h4>
          <p className="section-text">
            Now let't get to know what's causing harm to their home.
          </p>
        </div>

        <div id="section-container">
          <p>More to come on different kinds of threats </p>
        </div>

        {/* Ocean health visualisation  */}
        <div id="section-container">
          <div className="timeline-container">
            <div className="ocean-health-info intro">
              <h2>
                <strong>How Healthy Is Our Ocean?</strong>
              </h2>
              <h5>
                And what factors affects its health? Drag the timeline to see
                ocean health at different days!
              </h5>
              <br />
              <h3 style={{ fontWeight: "bolder" }}>
                {getMonthName(currentData.month)}/{currentData.year}
              </h3>
              <p>
                <strong>Overall Health Score:</strong>{" "}
                {getStars(currentData.all_score)}
              </p>
            </div>
            <div className="slider-container-health">
              <input
                type="range"
                min="0"
                max={oceanHealthData.length - 1}
                value={currentIndex}
                onChange={handleIndexChange}
                className="slider"
              />
              <div className="slider-labels">
                {getTimelineLabels().map(({ year, month }, index) => (
                  <span
                    key={index}
                    className={`slider-label`}
                  >
                    {`${month}/${year}`}
                  </span>
                ))}
              </div>
            </div>

            <div className="ocean-health-info" style={{ marginTop: "20px" }}>
              <p>
                Oxygen Level
                <button
                  type="button"
                  className="btn btn-secondary info-button"
                  onClick={() => handleInfoClick("oxygen")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-patch-question-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.933.87a2.89 2.89 0 0 1 4.134 0l.622.638.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01zM7.002 11a1 1 0 1 0 2 0 1 1 0 0 0-2 0m1.602-2.027c.04-.534.198-.815.846-1.26.674-.475 1.05-1.09 1.05-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.7 1.7 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745.336 0 .504-.24.554-.627"></path>
                  </svg>
                  <span className="tooltip">What is this?</span>
                </button>
                {currentData.oxygen?.toFixed(2)} mg/L{" "}
                {getStars(currentData.oxy)}
              </p>

              <p>
                Chlorophyll Level
                <button
                  type="button"
                  className="btn btn-secondary info-button"
                  onClick={() => handleInfoClick("chlorophyll")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-patch-question-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.933.87a2.89 2.89 0 0 1 4.134 0l.622.638.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01zM7.002 11a1 1 0 1 0 2 0 1 1 0 0 0-2 0m1.602-2.027c.04-.534.198-.815.846-1.26.674-.475 1.05-1.09 1.05-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.7 1.7 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745.336 0 .504-.24.554-.627"></path>
                  </svg>
                  <span className="tooltip">What is this?</span>
                </button>
                {currentData.chlorophyll?.toFixed(2)} µg/L{" "}
                {getStars(currentData.cho_score)}
              </p>

              <p>
                Salinity Level
                <button
                  type="button"
                  className="btn btn-secondary info-button"
                  onClick={() => handleInfoClick("salinity")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-patch-question-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.933.87a2.89 2.89 0 0 1 4.134 0l.622.638.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01zM7.002 11a1 1 0 1 0 2 0 1 1 0 0 0-2 0m1.602-2.027c.04-.534.198-.815.846-1.26.674-.475 1.05-1.09 1.05-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.7 1.7 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745.336 0 .504-.24.554-.627"></path>
                  </svg>
                  <span className="tooltip">What is this?</span>
                </button>
                {currentData.salinity?.toFixed(2)} PSU{" "}
                {getStars(currentData.sal)}
              </p>
            </div>

            {showPopup && (
              <div className="popup">
                <div className="popup-content">
                  <h3>{popupContent.title}</h3>
                  <img src={`/health/${popupContent.title}.png`} alt={popupContent.title}/>
                  {popupContent.content}
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={closePopup}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-x-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* quiz   */}
        <div id="section-container">
        <div id="intro-container">
          <img src="quiz.png" alt="quiz" style={{width:"500px"}} />
          <p className="section-text">
            Ready to take a quiz and test what you've learnt?
          </p>
          <button
            type="button"
            className="btn btn-outline-light"
            onClick={() => setIsQuizOpen(true)}
          >
            Start Quiz
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              fill="currentColor"
              className="bi bi-arrow-right-circle-fill ms-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
            </svg>
          </button>
          <QuizPopup
            isOpen={isQuizOpen}
            onClose={() => setIsQuizOpen(false)}
            quizData={quizData}
          />
        </div>
        </div>

        {/* Section Summary */}
        <div id="intro-container">
          <h4 className="section-heading">
            Now you have learnt about different marine threats!
          </h4>
          <p className="section-text">
            But what kind of threats are around you? Click on the next button to
            recognise different kinds of pollutions!
          </p>
          <button
            type="button"
            className="btn btn-outline-light"
            onClick={() => goToRoute("/ocean-pollution")}
          >
            Next
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              fill="currentColor"
              className="bi bi-arrow-right-circle-fill ms-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
            </svg>
          </button>
        </div>
      </div>
    </main>
  );
};

export default MarineThreats;
