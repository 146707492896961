import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./EndangeredSpecies.css";
import Header from "./Header";
import PopulationTimeline from "./PopulationTimeline";
import QuizPopup from "./QuizPopup";

// fish names and its location/size in div
const fishData = [
  { name: "Whale Shark", top: "5%", left: "20%", size: "35vh" }, // Largest fish
  { name: "Scalloped Hammerhead", top: "20%", left: "3%", size: "33vh" },
  { name: "Great Hammerhead", top: "35%", left: "35%", size: "30vh" },
  { name: "Humphead Maori Wrasse", top: "10%", left: "55%", size: "15vh" },
  { name: "Hawksbill Turtle", top: "22%", left: "5%", size: "10vh" }, // Smallest turtle
  { name: "Bumphead Parrotfish", top: "55%", left: "75%", size: "18vh" },
  { name: "Dugong", top: "52%", left: "10%", size: "15vh" }, // Large marine mammal
  { name: "White Shark", top: "45%", left: "45%", size: "30vh" }, // Large predator
  { name: "Green Turtle", top: "57%", left: "25%", size: "12vh" }, // Slightly larger than Hawksbill
  {
    name: "Australian Hump-backed Dolphin",
    top: "20%",
    left: "72%",
    size: "22vh",
  },
  { name: "Australian Snubfin Dolphin", top: "43%", left: "78%", size: "30vh" },
  { name: "New Zealand Sea Lion", top: "35%", left: "15%", size: "30vh" }, // Large sea lion
  { name: "Australian Sea-lion", top: "75%", left: "60%", size: "26vh" },
  { name: "Giant Shovelnose Ray", top: "80%", left: "30%", size: "34vh" },
  { name: "Whitespotted Guitarfish", top: "65%", left: "45%", size: "32vh" },
  { name: "Black Stingray", top: "0%", left: "82%", size: "20vh" },
  { name: "Porcupine Ray", top: "65%", left: "80%", size: "20vh" },
  { name: "Zebra Shark", top: "0%", left: "50%", size: "30vh" }, // Medium shark
  { name: "Mangrove Whipray", top: "15%", left: "60%", size: "22vh" },
  { name: "Pink Whipray", top: "25%", left: "88%", size: "20vh" },
  { name: "Cowtail Stingray", top: "65%", left: "23%", size: "18vh" },
  { name: "Tawny Shark", top: "65%", left: "5%", size: "24vh" },
  { name: "Prince Alfred's Ray", top: "1%", left: "2%", size: "20vh" },
];

const quizData = {
  topic: "Ocean Animals",
  level: "General Knowledge",
  totalQuestions: 5,
  perQuestionScore: 10,
  questions: [
    {
      question: "According to the video, where does Ms. Maugean Skate live?",
      choices: [
        "Sydney",
        "Western Tasmania",
        "Great Barrier Reef",
        "Eastern Australia",
      ],
      correctAnswer: "Western Tasmania",
      explanation:
        "Ms. Maugean Skate resides in Western Tasmania, which is known for its unique marine environments.",
      image: "/animalquiz/1.png",
    },
    {
      question: "What size am I, the Black Stingray?",
      choices: [
        "As big as a bicycle",
        "As small as a cat",
        "As wide as a bed",
        "As long as a table",
      ],
      correctAnswer: "As wide as a bed",
      explanation:
        "The Black Stingray can be as wide as a bed, showcasing its large size. Learn how to draw a Stingray by watching this video: [How to Draw Stingray](https://www.youtube.com/watch?v=PpEG9vFbxK0). For more facts about Stingrays, visit: [Stingray Facts](https://www.natgeokids.com/au/discover/animals/sea-life/stingray-facts/).",
      image: "/animalquiz/2.png",
    },
    {
      question: "What is a fun fact of Zebra Shark?",
      choices: [
        "I can sleep while swimming!",
        "I can change my color to blend in with my surroundings!",
        "I have stripes when I’m young but lose them as I grow!",
        "I have a special way of talking to other sharks!",
      ],
      correctAnswer: "I have stripes when I’m young but lose them as I grow!",
      explanation:
        "Zebra Sharks have stripes when they are young, which fade as they mature. This pattern change helps them blend into their surroundings and avoid predators.",
      image: "/animalquiz/3.png",
    },
    {
      question: "What is the Green Turtle's favorite snack?",
      choices: ["Jellyfish", "Seagrass", "Pizza", "Seaweed"],
      correctAnswer: "Seaweed",
      explanation:
        "Green Turtles enjoy munching on seaweed, one of their preferred snacks in their natural habitat. Seaweed provides essential nutrients that are vital for their health.",
      image: "/animalquiz/4.png",
    },
    {
      question: "What danger does my family face, as Porcupine Rays?",
      choices: [
        "We have no proper food.",
        "We get trapped in fishing nets.",
        "We forget where we live.",
        "We lose our way due to strong currents.",
      ],
      correctAnswer: "We get trapped in fishing nets.",
      explanation:
        "Porcupine Rays often face the danger of being trapped in fishing nets, which poses a significant threat to their survival. This can lead to injury or death, making it a critical issue for conservation efforts.",
      image: "/animalquiz/5.png",
    },
  ],
};

const EndangeredSpecies = () => {
  // handle pause/start of intro video
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef(null);
  const [selectedFish, setSelectedFish] = useState(null);
  const [fishInfo, setFishInfo] = useState(null);
  const [populationData, setPopulationData] = useState(null);
  const popupRef = useRef(null);
  const navigate = useNavigate();
  const [isQuizOpen, setIsQuizOpen] = useState(false);

  // nav
  const goToRoute = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  // video play/pause
  const handleVideoControl = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // handle click on animal to view info
  const handleFishClick = async (fishName) => {
    try {
      // Fetch fish info data
      const infoResponse = await fetch(
        `https://splash-db.onrender.com/api/species/${fishName}`
      );
      if (!infoResponse.ok) throw new Error("Failed to fetch fish info");
      const infoData = await infoResponse.json();
      setFishInfo(infoData);

      // Fetch population data
      const populationResponse = await fetch(
        `https://splash-db.onrender.com/api/fish-population/${fishName}`
      );
      if (!populationResponse.ok)
        throw new Error("Failed to fetch population data");
      const populationData = await populationResponse.json();
      setPopulationData(populationData);
      console.log("Population data", populationData);
      // Set the selected fish to trigger the popup
      setSelectedFish(fishName);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // close popup window and reset fish usestate
  const closePopup = useCallback(() => {
    setSelectedFish(null);
    setFishInfo(null);
    setPopulationData(null);
  }, []);

  // display different color coding based on endanger status
  const getConservationStatusColor = (status) => {
    switch (status) {
      case "Critically Endangered (CR)":
        return "#9c0b06";
      case "Endangered (EN)":
        return "#e62620";
      case "Vulnerable (VU)":
        return "#fa7211";
      case "Near Threatened (NT)":
        return "#fcde1c";
      case "Least Concern (LC)":
        return "#27c227";
      default:
        return "#043f6a";
    }
  };

  // when click outside the popup, also close popup
  const handleOutsideClick = useCallback(
    (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup(); // Use the memoized closePopup function
      }
    },
    [closePopup]
  ); // Depend on closePopup

  // use effect for tracking mouse clicks
  useEffect(() => {
    // Attach the event listener to the document
    document.addEventListener("mousedown", handleOutsideClick);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]); // Only re-run if handleOutsideClick changes

  return (
    <main>
      <Header />
      <div id="marine-container">
        {/* intro video  */}
        <div id="video-container">
          <video id="intro-video" ref={videoRef} autoPlay>
            <source src="/videos/Maugean Skate.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button id="video-control" onClick={handleVideoControl}>
            {isPlaying ? <span>&#10074;&#10074;</span> : <span>&#9658;</span>}
          </button>
        </div>

        <div id="section-container">
          <div style={{width: '100%'}}>
        <div id="intro-container" className="mb-0 pb-0">
          <h2 className="section-heading">
            Meet and Greet Some Ocean Animals!
          </h2>
          <p className="section-text">
            Click on each fish icon to learn more about them and
          </p>
          <p className="section-text">
            how their family has decreased.
            <img
              src="/fishicon/click.gif"
              alt="click"
              style={{ width: "100px" }}
            />
          </p>
        </div>

        {/* Fish tank div  */}
        <div id="background-container">
          {fishData.map((fish, index) => (
            <img
              key={index}
              src={`/fishicon/${fish.name}.png`}
              alt={fish.name}
              className="animal-icon"
              style={{
                top: fish.top,
                left: fish.left,
                width: fish.size,
                height: "auto",
              }}
              onClick={() => handleFishClick(fish.name)}
            />
          ))}

          {/* Pop up for selected fish  */}
          {selectedFish && fishInfo && (
            <div className="popup-species" ref={popupRef}>
              <div className="popup-content-species" id="popup-content-species">
                <h2 className="species-heading">{fishInfo.common_name}</h2>
                <div className="row justify-content-center mb-4">
                  <div className="col-auto text-center">
                    <img
                      src={`/fishid/${fishInfo.common_name}.png`}
                      alt={fishInfo.common_name}
                      className="id img-fluid rounded-circle"
                    />
                    <p
                      style={{
                        color: getConservationStatusColor(
                          fishInfo.conservation_status
                        ),
                      }}
                    >
                      <strong>Conservation Status:</strong>{" "}
                      {fishInfo.conservation_status}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 ml-1">
                    <p>
                      <strong>Scientific Name:</strong>{" "}
                      {fishInfo.scientific_name}
                    </p>
                    <p>
                      <strong>Size:</strong> {fishInfo.size_desc}
                    </p>
                    <p>
                      <strong>Favorite Snacks:</strong> {fishInfo.food}
                    </p>
                    <p>
                      <strong>Fun Fact:</strong> {fishInfo.fun_fact}
                    </p>
                    <p>
                      <strong>What Dangers My Family:</strong>
                    </p>
                    <ul>
                      {fishInfo.threats &&
                        JSON.parse(fishInfo.threats.replace(/'/g, '"')).map(
                          (threat, index) => <li key={index}>{threat}</li>
                        )}
                    </ul>
                  </div>

                  <div className="col-md-6">
                    <p>
                      <strong>Where I Live:</strong>
                    </p>
                    <ul>
                      {fishInfo.habitats &&
                        JSON.parse(fishInfo.habitats.replace(/'/g, '"')).map(
                          (habitat, index) => <li key={index}>{habitat}</li>
                        )}
                    </ul>
                    <p>
                      <strong>What My Home Looks Like:</strong>
                    </p>
                    <img
                      src={`/habitat/${fishInfo.common_name}.png`}
                      alt={`${fishInfo.common_name} habitat`}
                      className="habitat img-fluid rounded"
                    />
                  </div>
                </div>

                {/* Population Timeline */}
                {populationData && (
                  <PopulationTimeline speciesData={[populationData]} />
                )}
                <button
                  type="button"
                  className="btn btn-outline-danger close"
                  onClick={closePopup}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-x-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"></path>
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
        </div>
        </div>

        {/* quiz   */}
        <div id="section-container">
        <div id="intro-container">
          <img src="quiz.png" alt="quiz" style={{width:"500px"}} />
          <p className="section-text">
            Ready to take a quiz and test what you've learnt?
          </p>
          <button
            type="button"
            className="btn btn-outline-light"
            onClick={() => setIsQuizOpen(true)}
          >
            Start Quiz
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              fill="currentColor"
              className="bi bi-arrow-right-circle-fill ms-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
            </svg>
          </button>
          <QuizPopup
            isOpen={isQuizOpen}
            onClose={() => setIsQuizOpen(false)}
            quizData={quizData}
          />
        </div>
        </div>

        <div id="intro-container">
          <h4 className="section-heading">
            Good job on getting to know some ocean animal friends!
          </h4>
          <p className="section-text">
            But what damages their homes? Click on the next button to start
            learning!
          </p>
          <button
            type="button"
            className="btn btn-outline-light"
            onClick={() => goToRoute("/marine-threats")}
          >
            Next
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              fill="currentColor"
              className="bi bi-arrow-right-circle-fill ms-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
            </svg>
          </button>
        </div>
      </div>
    </main>
  );
};

export default EndangeredSpecies;
